var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex align-items-center", attrs: { cols: "6" } },
            [
              _c("div", { staticClass: "mr-3 text-color-australien" }, [
                _c("div", { staticClass: "fs-14" }, [
                  _vm._v(_vm._s(_vm.labelForShowRecordsLoaded)),
                ]),
              ]),
            ]
          ),
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("div", { staticClass: "float-right" }, [
              _c(
                "div",
                { staticClass: "d-flex w-100" },
                [
                  _c(
                    "div",
                    { staticClass: "mr-3 w-100-px" },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.perPages,
                          label: "text",
                          reduce: (option) => option.value,
                          clearable: false,
                        },
                        on: { input: _vm.handleChangePerPage },
                        model: {
                          value: _vm.perPage,
                          callback: function ($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-flex align-items-center mr-2 px-3",
                      attrs: {
                        variant: "primary",
                        size: "sm",
                        disabled: _vm.disableButtonPrev,
                      },
                      on: { click: _vm.handleClickPrevious },
                    },
                    [
                      _c("skip-back", { attrs: { size: 16 } }),
                      _c(
                        "div",
                        {
                          staticClass: "ml-2",
                          staticStyle: { "margin-top": "1px" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(23, "Prev")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "d-flex align-items-center px-3",
                      attrs: {
                        variant: "primary",
                        size: "sm",
                        disabled: _vm.disableButtonNext,
                      },
                      on: { click: _vm.handleClickNext },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mr-2",
                          staticStyle: { "margin-top": "1px" },
                        },
                        [_vm._v(_vm._s(_vm.FormMSG(24, "Next")))]
                      ),
                      _c("skip-forward", { attrs: { size: 16 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }